@import "bootstrap/bootstrap.less";

@brand-main: #4cb74c;

//Media screens
@screen-tablet-landscape: 1024px;
@screen-crappy-laptop: 1366px;
@screen-xlg: 1600px;

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: -1px;
}

a {
  cursor: pointer;
}

.black {
  color: black;
}

.clickable {
  cursor: pointer;
}

.screens-options {
  background-color: #3d3d3d;
  color: #7b7b7b;
  height: 100vh;
  z-index: 1000;
  padding-bottom: 172px;

  @media only screen
  and (max-width: @screen-md) {
    background: none;
    height: auto;
    padding-bottom: 0px;
    text-align: center;
    padding: 20px;
  }

  .scroll-wrapper {
    overflow: auto;
    height: 100%;
    margin-left: -15px;
    margin-right: -15px;
    padding: 30px 15px 30px 15px;

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    @media only screen
    and (max-width: @screen-md) {
      overflow: visible;
      height: auto;
      padding: 0;
      margin: 0;
    }
  }

  .sliding-menu {
    @media only screen
    and (max-width: @screen-md) {
      background: #3d3d3d;
      position: fixed;
      left: -100%;
      top: 0;
      width: 70%;
      min-width: 300px;
      z-index: 9998;
      bottom: 0;
      overflow: auto;
      padding: 20px;
      padding-top: 5px;
      transition: all 0.5s ease;
      &.visible {
        left: 0;
      }
    }

    h2 {
      margin-bottom: 25px;

    }
  }

  .menu-bars {
    display: none;
    @media only screen
    and (max-width: @screen-md) {
      display: block;
    }
    color: black;
    font-size: 3rem;
    float: left;
    margin-top: 5px;
    position: absolute;
    z-index: 9999;
    text-shadow: 0px 0px 28px rgba(255, 255, 255, 0.5);

    &.fixit {
      position: fixed;
    }
  }

  .logo {
    margin-bottom: 50px;

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      margin-bottom: 25px;
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      margin-bottom: 15px;
    }

    @media only screen
    and (max-width: @screen-md) {
      max-width: 50%;
      max-height: 50px;
      margin: 0;
      text-align: center;
      display: inline;
    }
  }

  .screens-navigation {
    position: relative;
    line-height: 32px;
    margin-bottom: 40px;

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      margin-bottom: 20px;
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      margin-bottom: 10px;
    }

    @media only screen
    and (max-width: @screen-md) {
      margin: 20px auto 0 auto;
    }

    .fa {
      font-size: 32px;
      color: #000000;

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-crappy-laptop) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    a {
      z-index: 1100;
      position: relative;
    }

    .screen-selector {
      position: absolute;
      left: 30px;
      right: 30px;
      top: -12px;
      z-index: 1050;
      overflow: hidden;
      bottom: 0;
      line-height: 250%;
      padding: 10px 0;

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-crappy-laptop) {
        left: 15px;
        right: 15px;
      }

      @media only screen
      and (min-width: @screen-md) {
        overflow: visible;
        background: #cdcdcd;
        bottom: auto;
        border: 1px solid #2d2d2d;
        .border-bottom-radius(5px);
        .border-top-radius(5px);

        .screen-title {
          &.active {
            color: #3f3f3f;
          }

          &:hover {
            //font-size: 26px;
            color: #3f3f3f;
          }
        }
      }

      @media only screen
      and (max-width: @screen-md) {
        display: none;
      }
    }

    .current-screen-name {
      position: absolute;
      left: 30px;
      right: 30px;
      z-index: 1050;
      overflow: hidden;
      text-align: center;
      cursor: pointer;

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-crappy-laptop) {
        left: 15px;
        right: 15px;
      }
    }

    .screen-title, .current-screen-name {
      display: block;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      white-space: nowrap;
      color: #7b7b7b;

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-xlg) {
        font-size: 16px;
      }

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-crappy-laptop) {
        font-size: 13px;
      }

      .transition(~"color 0.3s ease, font-size 0.3s ease");

      &.active {
        font-size: 26px;

        @media only screen
        and (min-width: @screen-tablet-landscape)
        and (max-width: @screen-xlg) {
          font-size: 20px;
        }

        @media only screen
        and (min-width: @screen-tablet-landscape)
        and (max-width: @screen-crappy-laptop) {
          font-size: 16px;
        }
      }
    }
  }

  hr {
    border-color: #3d3d3d;
    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      display: none;
    }
  }

  .form-group {
    label {

      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 18px;
      padding-left: 10px;

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-xlg) {
        font-size: 14px;
      }

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-xlg) {
        font-size: 12px;
      }

      @media only screen
      and (max-width: @screen-md) {
        font-size: 14px;
      }

      &.label-lg {
        font-size: 150%;
        @media only screen
        and (min-width: @screen-tablet-landscape)
        and (max-width: @screen-xlg) {
          font-size: 120%;
        }

        @media only screen
        and (min-width: @screen-tablet-landscape)
        and (max-width: @screen-xlg) {
          font-size: 14px;
        }

        @media only screen
        and (max-width: @screen-md) {
          font-size: 14px;
        }
      }
    }

    .input-lg {

    }

  }

  input, select, .input-group-addon {
    background-color: #212121;
    border: none;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;

    &.no-transform {
      text-transform: none;
    }

    &[disabled] {
      background-color: #212121;
      color: #ffffff;
    }

    &.not-bold {
      font-weight: 400;
      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-xlg) {
        font-weight: 700;
      }
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      font-size: 14px;
      height: 40px;
      font-weight: 300;
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-crappy-laptop) {
      font-size: 12px;
      height: 30px;
    }

    @media only screen
    and (max-width: @screen-md) {
      font-size: 16px;
      font-weight: bold;
    }

    &.input-lg {
      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-xlg) {
        font-size: 16px;
      }

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-crappy-laptop) {
        font-size: 14px;
        padding-top: 5px;
      }

      @media only screen
      and (max-width: @screen-md) {
        font-size: 14px;
        font-weight: 700;
        height: 34px;
        padding: 6px 12px;
        line-height: 1.42857143;
      }
    }
  }

  .input-group-addon {
    text-transform: none !important;
    font-size: 34px;
    color: #777676;

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      font-size: 24px;
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-crappy-laptop) {
      font-size: 16px;
    }

    @media only screen
    and (max-width: @screen-md) {
      font-size: 14px;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: rgba(181, 181, 181, 20%);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  z-index: 1500;

  @media only screen
  and (max-width: @screen-md) {
    position: relative;
    bottom: auto;
    width: auto;
    background: none;
    z-index: 500;
  }

  .knockout {
    @media only screen
    and (max-width: @screen-md) {
      background: rgba(181, 181, 181, 20%);
    }
  }

  .actions-wrapper, .video-actions-wrapper {
    position: absolute;
    right: 0.7em;
    top: -4.5em;

    .fa-over-screen {
      margin-left: 0.3em;
      .border-bottom-radius(84px);
      .border-top-radius(84px);
      .transition(background 0.2s ease);

      &:hover {
        background: #e6e6e6;
        .box-shadow(0px 0px 3px fade(#000000, 50));
      }

      &.reset-button {
        //right: 3em;
      }

      &.all-button {
        //right: 5.7em;
        color: #000000;
        text-decoration: none;
        text-align: center;
      }
    }
  }

  .video-actions-wrapper {
    right: auto;
    left: 17%;
  }

  .fa {
    color: #000000;
  }

  .output-bar {
    padding-top: 20px;
  }

  dt, dd {
    line-height: 220%;
    font-size: 20px;
    white-space: nowrap;

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      font-size: 18px;
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      font-size: 16px;
    }

    @media only screen
    and (max-width: (@screen-md)) {
      float: left;
      font-size: 16px;
    }

    @media only screen
    and (min-width: (@screen-sm))
    and (max-width: (@screen-md)) {
      font-size: 22px;
    }
  }

  dt {
    width: auto;

    &.muted {
      color: #616060;
    }

    &:after {
      content: ":";
    }

    @media only screen
    and (max-width: (@screen-md)) {
      clear: both;
      width: 60%;
      padding: 0 10%;
      width: 65%;
      padding: 0 2%;
    }

    @media only screen
    and (min-width: (@screen-sm))
    and (max-width: (@screen-md)) {
      width: 50%;
    }
  }

  dd {
    @media only screen
    and (min-width: @screen-crappy-laptop)
    and (max-width: @screen-xlg) {
      margin-left: 150px;
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-crappy-laptop) {
      margin-left: 120px;
    }

    @media only screen
    and (min-width: (@screen-sm))
    and (max-width: (@screen-md)) {
      margin-left: 0;
    }
  }
}

.video-actions-wrapper, .actions-wrapper {
  .fa-stack {
    font-size: 1.4em;
  }
}

.read-only {
  .video-actions-wrapper {
    left: 0.7em;
  }
}

.ios-device {
  .screen {
    .embed-video-wrapper {
      z-index: 0;
      opacity: 0.9;
    }
  }

  grid {
    &.video-playing, &.video-paused, &.video-init {
      .embed-video-wrapper {
        z-index: 1;
      }

      .screen-panel {
        pointer-events: none;
      }
    }

    &.video-playing {
      .screen-panel {
        opacity: 1;
      }
    }
  }
}

#tour-screen-title {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: -100;
}

.screen {
  width: 60%;
  margin: auto;
  margin-top: 200px;
  position: relative;
  background: #ffffff;

  @media only screen
  and (min-width: @screen-tablet-landscape)
  and (max-width: @screen-crappy-laptop) {
    margin-top: 130px;
  }

  @media only screen
  and (max-width: @screen-md) {
    width: 80% !important;
    margin-top: 0px;
  }

  .silhouette {
    background: url(/img/silhouette.svg) no-repeat left bottom;
    background-size: contain;
    overflow: visible;
    height: 0px;
    width: 500px;
    position: absolute;
    bottom: 0;
    left: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
    .transition(all 0.3s ease);

    @media only screen
    and (max-width: @screen-md) {
      display: none;
    }
  }

  .screen-panel {
    cursor: pointer;
    background: url(/img/panel-v39.jpg) no-repeat 0 0;

    .cover();
    width: percentage(1/12);
    padding-bottom: percentage(1/12);
    height: 0;
    float: left;
    position: relative;
    overflow: hidden;
    opacity: 1;
    .transform(scale(1, 1));
    .transition(all 0.15s ease);

    &.disabled {
      opacity: 1;
      z-index: 5;
      background: #ffffff !important;
      .transform(scale(-1, 1));
    }

    &.panel-type-7 {
      background-image: url(/img/panel-v7.png);
    }

    &.panel-type-901 {
      background-image: url(/img/panel-vcurve.jpeg);
    }
  }

  .screen-title {
    position: absolute;
    z-index: 100;
    opacity: 0.3;
    color: #ffffff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 300px;
    line-height: 100%;
    margin: auto;
    text-align: center;
    pointer-events: none;
    .transition(all 0.15s ease);

    @media only screen
    and (max-width: @screen-md) {
      font-size: 25vw !important;
      line-height: 1.5em !important;
    }
  }

  .measurment-arrow {
    position: absolute;
    width: 100%;
    top: -100px;
    opacity: 1;
    .transition(all 0.5s ease);

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-crappy-laptop) {
      top: -50px;
    }

    .arrow-label {
      text-align: center;
      font-size: 34px;
      text-transform: none !important;

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-xlg) {
        font-size: 24px;
      }

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-crappy-laptop) {
        font-size: 20px;
      }

    }

    .arrow {
      width: 100%;
      height: 10px;
      position: relative;

      .fa {
        color: #7c7c7c;
      }

      &:after {
        content: "";
        display: block;
        height: 3px;
        width: calc(~"100% - 6px");
        left: 3px;
        position: absolute;
        top: 5px;
        background: #7c7c7c;
      }
    }

    &.horizontal-arrow {
      white-space: nowrap;
    }

    &.vertical-arrow {
      height: 100%;
      width: auto;
      top: 0;
      left: -60px;

      @media only screen
      and (min-width: @screen-tablet-landscape)
      and (max-width: @screen-crappy-laptop) {
        left: -30px;
      }

      .fa-chevron-down {
        bottom: 0px;
        position: absolute;
      }

      .arrow-label {
        text-align: right;
        position: absolute;
        top: 45%;
        left: -120px;

        @media only screen
        and (min-width: @screen-tablet-landscape)
        and (max-width: @screen-xlg) {
          left: -90px;
        }

        @media only screen
        and (min-width: @screen-tablet-landscape)
        and (max-width: @screen-crappy-laptop) {
          left: -75px;
        }
      }

      .arrow {
        width: 10px;
        height: 100%;

        &:after {
          width: 3px;
          top: 7px;
          left: 6px;
          bottom: 3px;
          height: calc(~"100% - 10px");
        }
      }
    }
  }

  .embed-video-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0;

    &.vertical {
      embed-video {
        padding: 0;
        width: auto;
        right: -300%;
        left: -300%;
        height: 100%;
      }
    }

    embed-video {
      position: absolute;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;
      box-sizing: content-box;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      iframe {
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
      }
    }

  }
}

#bottomRow {
  .fa-stack {
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    margin: 0.25em;
    font-size: 2.8em;
    .border-bottom-radius(84px);
    .border-top-radius(84px);
    .transition(background 0.2s ease);

    &:hover {
      background: #e6e6e6;
      .box-shadow(0px 0px 3px fade(#000000, 50));
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      font-size: 1.4em;
    }
  }

  .actions-box {
    padding-top: 10px;

    a.disabled {
      opacity: 0.5;
    }

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      padding-top: 35px;
    }
  }
}

.read-only {
  .actions-box {
    background: #555555;
    min-height: 172px;

    @media only screen
    and (min-width: @screen-tablet-landscape)
    and (max-width: @screen-xlg) {
      min-height: 145px;
    }
  }
}

.screens-wrapper {
  height: 100vh;
  overflow: hidden;

  .screens-window-navigation {
    position: absolute;
    top: 40%;
    left: 2%;
    z-index: 5000;
    font-size: 4em;
    color: #000;
    opacity: 0.3;
    .transition(opacity 0.2s ease);

    &:hover {
      opacity: 0.8;
    }

    &.screens-window-navigation-next {
      left: auto;
      right: 2%;
    }
  }

  @media only screen
  and (max-width: @screen-md) {
    height: auto;
    overflow: visible;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

grid {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 50;
  opacity: 0.5;
  min-height: 71%;
  .transition(all 1s ease);

  @media only screen
  and (max-width: @screen-md) {
    position: relative;
    left: auto;
    right: auto;
    opacity: 1;
  }

  &.video-playing {
    .screen-panel {
      opacity: 0;
    }
  }

  &.video-playing {
    .panels-offset-active {
      .screen-panel {
        opacity: 1;
      }
    }
  }

  &.video-paused {
    .screen-panel {
      opacity: 0.8;
    }
  }

  &.zoom-out {
    &.screen-num-0 {
      opacity: 1 !important;
      .transform(~"translate(-25%, -25%) scale(0.5) !important");
    }

    &.screen-num-1 {
      opacity: 1 !important;
      .transform(~"translate(25%, -25%) scale(0.5) !important");
    }

    &.screen-num-2 {
      opacity: 1 !important;
      .transform(~"translate(-25%, 25%) scale(0.5) !important");
    }

    &.screen-num-3 {
      opacity: 1 !important;
      .transform(~"translate(25%, 25%) scale(0.5) !important");
    }

    .zoom-out-overlay {
      display: block;
    }

    .silhouette {
      opacity: 0.8;
    }
  }

  .zoom-out-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 1;
    cursor: pointer;
    @media only screen
    and (max-width: @screen-md) {
      //display: block !important;
    }
  }

  &.active {
    opacity: 1;
    .transform(translate(0px, 0px) scale(1));
    @media only screen
    and (max-width: @screen-md) {
      display: block;
    }

    .silhouette {
      opacity: 0.8;
    }
  }

  &.hidden-screen {
    .transform(scale(0));
    opacity: 0;
  }

  &.left-side, &.right-side, &.hidden-screen {
    z-index: 40;

    .measurment-arrow {
      opacity: 0;
    }

    @media only screen
    and (max-width: @screen-md) {
      display: none;
    }
  }

  &.left-side {
    .transform(translate(-35%, 0px) scale(0.8));
  }

  &.right-side {
    .transform(translate(35%, 0px) scale(0.8));
  }
}

.mobile-project-title {
  display: none;
  color: @brand-main;
  @media only screen
  and (max-width: @screen-md) {
    display: block;
    position: relative;
    font-size: 28px;
    margin-top: 0px;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 30px;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;

    &:before {
      content: "";
      display: block;
      border: 1px solid #eee;
      position: absolute;
      width: 200%;
      top: 15px;
      left: -25%;
    }
  }
}

.product-name {
  color: #000;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.screens-options {
  .mobile-edit-title-input {
    @media only screen
    and (max-width: @screen-md) {
      display: block;
    }
    display: none;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    border: none !important;
    background: #e6e6e6 !important;
    .h1;
    font-size: 26px !important;
    margin: 0;

    color: @brand-main !important;
    .border-bottom-radius(5px);
    .border-top-radius(5px);
    .box-shadow(0px 0px 3px fade(#000000, 50));
  }
}

.current-screen-name input {
  font-size: 26px;
  text-transform: none;
  text-align: center;
  width: 100%;
  border: none !important;
  background: #e6e6e6 !important;
  color: #7b7b7b;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
}

.project-title, .screen-name {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 0px;
  cursor: text;
  z-index: 5600;

  @media only screen
  and (max-width: @screen-md) {
    display: none;
  }

  .center-wrapper {
    display: inline-block;

    h1 {
      display: inline-block;
      color: @brand-main;

      span {
        padding-top: 10px;
        opacity: 1;
        display: inline-block;

        &.hide {
          opacity: 0;
          z-index: -1000;
        }

        &.default {
          color: #000000;
          opacity: 0.4;
        }
      }
    }

    input {
      width: 100%;
      display: block;
      .h1;
      text-transform: uppercase;
      text-align: center;
      border: none;
      background: #e6e6e6;
      margin: 0;
      margin-top: -15px;
      color: @brand-main;
      .border-bottom-radius(5px);
      .border-top-radius(5px);
      .box-shadow(0px 0px 3px fade(#000000, 50));
    }
  }
}

.screen-name {
  top: auto;
  bottom: -50px;

  .center-wrapper {
    //display: inline-block;

    h1 {
      //display: inline-block;
      //color: @brand-main;
      font-size: 24px;
      text-transform: none;
      margin-top: 0;

      span {
        padding-top: 0;
        //	padding-top: 10px;
        ////	opacity: 1;
        //	display: inline-block;

        &.hide {
          //		opacity: 0;
          //		z-index: -1000;
        }

        &.default {
          //		color: #000000;
          //		opacity: 0.4;
        }
      }
    }

    input {
      font-size: 24px;
      text-transform: none;
      //	width: 100%;
      //	display: block;
      //	.h1;
      //	text-transform: uppercase;
      //	text-align: center;
      //	border: none;
      //	background: #e6e6e6;
      //		margin: 0;
      //	margin-top: -15px;
      //	color: @brand-main;
      //	.border-bottom-radius(5px);
      //		.border-top-radius(5px);
      //		.box-shadow(0px 0px 3px fade(#000000, 50));
    }
  }
}

.output-only-explanation {
  display: block;
  text-align: center;
  background: @brand-main;
  margin: 20px auto;
  width: 40px;
  height: 40px;
  .border-bottom-radius(20px);
  .border-top-radius(20px);
  color: #ffffff;
  font-size: 30px;
  line-height: 40px;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
}

.draw-select {
  background: #CCCCCC;
  line-height: 150%;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;

  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  text-indent: 0.01px; // Need this to hide default select in FF
  text-overflow: ''; // Need this to hide default select in FF
  &::-ms-expand {
    display: none; // Need this to hide default select in IE10
  }

  &[disabled] {
    background: none;
    cursor: default;
    padding-left: 0;
    padding-right: 0;
  }
}

._720kb-tooltip {
  z-index: 2000;
  letter-spacing: 0px;
}

ul.in-tour {
  padding: 0;

  li {
    padding-top: 10px;
  }
}

.read-only .screen-panel {
  cursor: default;
}

.hide-buttons {
  .sa-button-container {
    display: none;
  }
}

.no-upper-case {
  text-transform: none;
}

//Mixins

.cover () {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.transform(@string) {
  -webkit-transform: @string;
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
}

.gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
}

.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow: @string;
  box-shadow: @string;
}

div.hopscotch-bubble {
  @media only screen
  and (max-width: @screen-md) {
    display: none;
  }
}

/*
.fixfixed .footer {
	position: absolute;
}
*/

.screen-panel {
  overflow: visible !important;

  .xOffsetHandle {
    z-index: 8000;
    opacity: 0;
    display: block;
    position: absolute;
    width: 20px;
    top: 0;
    height: 100%;
    line-height: 0px;
    padding-top: 50%;
    font-size: 40px;
    color: #000;

    &.leftHandle {
      left: -20px;
    }

    &.rightHandle {
      right: -20px;
      text-align: right;
    }

    @media only screen
    and (max-width: @screen-md) {
      display: none;
    }
  }

  .yOffsetHandle {
    z-index: 8000;
    opacity: 0;
    display: block;
    position: absolute;
    height: 20px;
    left: 0;
    width: 100%;
    line-height: 20px;
    text-align: center;
    font-size: 40px;
    color: #000;

    span {
      margin-left: 5px;
      display: block;
      .transform(rotate(90deg));
    }

    &.topHandle {
      top: -20px;
    }

    &.bottomHandle {
      bottom: -20px;
    }

    @media only screen
    and (max-width: @screen-md) {
      display: none;
    }
  }
}

.screen-panel {
  &.disabled {
    .xOffsetHandle {
      &.leftHandle {
        left: 100%;
      }

      &.rightHandle {
        right: 100%;
      }
    }
  }
}

.screen-panel:hover {
  .xOffsetHandle, .yOffsetHandle {
    opacity: 1;
  }

  &.disabled {
    .xOffsetHandle {

      .transform(scale(-1, 1));

      &.leftHandle {
        right: -20px;
      }

      &.rightHandle {
        left: -20px;
        right: auto;
      }
    }
  }
}

.offline-status {
  position: absolute;
  text-align: right;
  top: 10px;
  right: 15px;
  text-transform: uppercase;
  color: #616060;
}

.status-fade {
  transition: 0.5s ease all;
  opacity: 1;
}

.status-fade.ng-hide {
  opacity: 0;
}

.sweet-alert {
  max-height: 94%;
  overflow: scroll;

  &.top-position {
    top: 15px !important;
    margin-top: 0 !important;
  }
}